<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('users.edit-user', {user : user.enterpriseShortName}) }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <UserForm :user="user" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import UserForm from "@/components/admin/user/UserForm.vue";

export default {
  name : "EditUser",
  components: { NavBar, UserForm },
  data : function () {
    return {
      user: null
    }
  },
  created: function() {
    this.$http.get('/cms/api/users/get/email/' +  window.btoa(unescape(encodeURIComponent(this.$route.params.username))) + "/" + this.$root.lang.IANA, { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
      this.user = r.data;
    }).catch(e => { this.handleError(e); })
  }
}
</script>
<style></style>
